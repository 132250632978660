import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/libs/shared/notification/notification.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/libs/shared/popups/PopupLayout.jsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"src/utils/constants/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/AeonikPro-Light.woff2\",\"weight\":\"300\"},{\"path\":\"../../../public/fonts/AeonikPro-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../../../public/fonts/AeonikPro-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"../../../public/fonts/AeonikPro-Bold.woff2\",\"weight\":\"700\"},{\"path\":\"../../../public/fonts/AeonikPro-Black.woff2\",\"weight\":\"900\"}],\"variable\":\"--font-aeonik\"}],\"variableName\":\"aeonik\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"src/utils/constants/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/libs/styles/wp-standard.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/app/libs/styles/fullScreenLoader.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/toastr/build/toastr.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/wpspotlight.pro/src/app/registry.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/wpspotlight.pro/src/components/global/animation-container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/wpspotlight.pro/src/components/media-modal/ModalTrigger.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/wpspotlight.pro/src/components/navigation/navbarClient.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/wpspotlight.pro/src/components/QueryClientProviderWrapper/QueryClientProviderWrapper.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextHoverEffect"] */ "/usr/src/app/sites/wpspotlight.pro/src/components/ui/text-hover-effect.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/sites/wpspotlight.pro/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/sites/wpspotlight.pro/styles/blog.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/app/sites/wpspotlight.pro/styles/index.scss");
